@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.about-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px;
  text-align: center;
}

.banner-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
}

.banner-image {
  width: 100%;
  height: auto;
}

.book-now-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1b09d1;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.mission-vision-values {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 500px;
  width: 900px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  margin: 30px;
  border-radius: 8px;
  background-color: #f8f7ff;
  overflow: hidden;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.section.visible {
  opacity: 1;
  animation: fadeInUp 1s ease-in-out; 
}

.card-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-image {
  height: 100%;
  width: 450px;
  height: 500px;
  border-radius: 2px;
  object-fit: cover;
}

.card-text {
  flex: 2;
  padding: 20px;
  font-size: 30px;
}

.title {
  color: #1b09d1;
  font-family: 'Lexend';
  font-size: 3rem;
  font-weight: 900;
  padding: 20px;
}

.paragraph {
  font-family: 'Lexend', sans-serif;
  font-size: 20px;
  margin-bottom: 20px;
}

.card-heading {
  font-family: 'Lexend', sans-serif;
  font-size: 2rem;
  margin-bottom: 10px;
  color: #1b09d1;
}

.button {
  background-image: linear-gradient(to right, rgba(31, 5, 206, 0.975), rgb(10, 10, 10), rgb(20, 6, 52));
  background-size: 300%;
  background-position: left;
  border-radius: 25px;
  border-width: 0;
  color: #ffffff;
  font-size: 1.1rem;
  padding: 5px;
  font-family: Lexend;
  font-weight: 900;
  width: 200px;
  height: 50px;
  box-shadow: 1px -1px 10px 1px #2c2c2c, 1px 1px 6px #282828;
  transition: background-position 0.5s ease-in-out, box-shadow 0.5s ease;
  margin-top: 15%;
}

.card-paragraph {
  font-family: 'Lexend', sans-serif;
  font-size: 24px;
}

.span-element {
  color: #1b09d1;
  font-weight: bold;
}

.span-title {
  color: #1b09d1;
  font-family: 'Lexend', sans-serif;
  font-weight: 700;
}

@media (max-width: 768px) {
  .section {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem;
    margin: 1rem 0;
  }

  .card-image-container {
    width: 100%;
    margin-bottom: 1rem;
  }

  .card-image {
    width: 100%;
    height: auto;
  }

  .card-text {
    width: 100%;
    font-size: 18px;
    padding: 1rem;
  }

  .title {
    font-size: 28px;
  }

  .paragraph {
    font-size: 18px;
  }

  .card-heading {
    font-size: 20px;
  }

  .card-paragraph {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .section {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem;
    margin: 1rem 0;
  }

  .card-image-container {
    width: 100%;
    margin-bottom: 1rem;
  }

  .card-image {
    width: 100%;
    height: auto;
  }

  .card-text {
    width: 100%;
    font-size: 18px;
    padding: 1rem;
  }

  .title {
    font-size: 28px;
  }

  .paragraph {
    font-size: 18px;
  }

  .card-heading {
    font-size: 20px;
  }

  .card-paragraph {
    font-size: 14px;
  }
}
