/* Full-page layout styling */
.enquiry-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #f8f7ff;
  padding: 0; /* Remove extra padding */
  box-sizing: border-box;
  margin: 0; /* Reset margin */
}

/* Container styling */
.trucks-form-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%; /* Adjust the width for better centering */
  max-width: 10000px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  margin: auto; /* Ensure container is centered */
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

/* Left section with the logo */
.form-left {
  background-color: #f1f4ff;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  padding-top: 200px;
  padding-bottom: 200px;
}

.form-title {
  color: #1b09d1;
  font-weight: bold;
  font-size: 50px;
  text-align: center;
}

/* Right section with the form */
.form-right {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.subform {
  display: flex;
  align-items: center;
  gap: 10px;
}

.loc-button {
  background-color: #1b09d1;
  color: #fff;
  border: none;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

.loc-button i {
  font-size: 18px;
}

.user-type-options {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.user-type-options button {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #e9e9e9;
  cursor: pointer;
}

.user-type-options button.active {
  background-color: #1b09d1;
  color: #fff;
}

.enquiry-submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #1b09d1;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.enquiry-submit-btn:hover {
  background-color: #0f083f;
}

.form-group-buttons {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .trucks-form-container {
    flex-direction: column;
    height: auto;
    width: 90%; /* Adjust width on smaller screens */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.error {
  position: absolute;
  top: 100%;
  left: 0;
  color: #d8000c;
  background-color: white;
  border: 1px solid #d8000c;
  padding: 12px 12px;
  border-radius: 4px;
  font-size: 12px;
  width: max-content;
  white-space: nowrap; /* Prevents text from wrapping */
  z-index: 10;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 2px; /* Adds a small gap between the input and error message */
}

.error::before {
  content: '';
  position: absolute;
  top: -5px;
  left: 10px; /* Adjust this to align the arrow correctly */
  border-width: 0 5px 5px 5px;
  border-style: solid;
  border-color: transparent transparent #d8000c transparent;
}


input:invalid + .error {
  display: block;
}

/* Popup styles for TrucksForm */
.detailed-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.5s ease-in-out;
}

.detailed-popup-content {
  background-color: #fff;
  padding: 60px;
  border-radius: 8px;
  width: 1000px;
  text-align: center;
  position: relative;
  animation: popupShow 0.6s ease-out;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.detailed-close-popup-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.detailed-close-popup-btn:hover {
  background-color: #eee;
}

.detailed-popup-table-centered {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.detailed-popup-form-data-table {
  width: 80%;
  text-align: left;
  margin: 0 auto;
  border-collapse: collapse;
  animation: fadeInTable 1s forwards;
}

.detailed-popup-form-data-table td {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards;
}

.detailed-popup-form-data-table tr:nth-child(1) td { animation-delay: 1.8s; } /* Each row delayed by 0.1s after table appearance */
.detailed-popup-form-data-table tr:nth-child(2) td { animation-delay: 1.9s; }
.detailed-popup-form-data-table tr:nth-child(3) td { animation-delay: 2.0s; }
.detailed-popup-form-data-table tr:nth-child(4) td { animation-delay: 2.1s; }
.detailed-popup-form-data-table tr:nth-child(5) td { animation-delay: 2.2s; }
.detailed-popup-form-data-table tr:nth-child(6) td { animation-delay: 2.3s; }
.detailed-popup-form-data-table tr:nth-child(7) td { animation-delay: 2.4s; }

.detailed-popup-comments-container {
  text-align: center;
  margin-top: 20px;
  opacity: 0;
  animation: fadeInComments 1s 2.5s forwards; /* Delayed for 2.5s after table rows */
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  max-width: 100%;
  align-items: center;
}

/* Animations */
@keyframes fadeIn {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

@keyframes popupShow {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.detailed-popup-checkmark {
  width: 50px;
  height: 50px;
  margin: 20px auto;
}

.detailed-checkmark-circle {
  stroke: #205a20;
  stroke-width: 2;
  fill: none;
  animation: checkmarkDraw 1s ease-out forwards;
}

.detailed-checkmark-check {
  stroke: #205a20;
  stroke-width: 5;
  fill: none;
  stroke-dasharray: 100px;
  stroke-dashoffset: 100px;
  animation: checkmarkDraw 0.5s 0.5s ease-out forwards;
}

@keyframes checkmarkDraw {
  0% {
    stroke-dashoffset: 100px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
}

/* Ensuring button looks good on hover */
.detailed-popup-content button:hover {
  background-color: #f0f0f0;
}

/* Ensuring text is centered properly in buttons */
.detailed-popup-content button {
  text-align: center;
}
.detailed-address-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: 20px;
  opacity: 0;
  animation: fadeInContainer 0.5s forwards; /* Delayed for 1.1s after the tick animation */
}

.detailed-pickup-container,
.detailed-drop-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  flex: 1;
  max-width: 45%;
}
