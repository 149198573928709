/* Overall page container */
.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 5%;
  justify-content: center;
  background-color: #f5f5f5; /* Light background for contrast */
  box-sizing: border-box;
  overflow: hidden;
  gap: 10%;
}

/* Left section with logo */
.logo-section {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  justify-content: space-between;
  gap: 20%;
  margin-bottom: 20px;
  padding: 20px;
}

.login-logo {
  max-width: 80%;
  height: auto;
}

.title {
  margin: 20px 0;
  text-align: center;
  font-size: 24px;
  color: #1b09d1;
}

.span-title {
  color: #007bff;
}

/* Right section with form */
.login-container {
  width: 100%;
  max-width: 400px;
  max-height: 80vh; /* Limit height to 80% of viewport height */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

/* Form input styling */
.inputBox {
  width: 100%;
  margin: 10px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left; /* Align text to the left */
}

.inputBox input {
  width: 100%;
  padding: 12px; /* Slightly reduced padding */
  box-sizing: border-box;
  border: 1px solid #1b09d1;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
  font-size: 14px; /* Consistent font size */
}

.inputBox input:focus {
  border-color: #1b09d1;
}

.inputBox label {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.5s;
}

.inputBox input:focus + label,
.inputBox input:not(:placeholder-shown) + label {
  top: -10px;
  left: 10px;
  color: #1b09d1;
  font-size: 12px;
  padding: 0 5px;
}

/* Submit button styling */
.submit-btn, .submit-otp-btn {
  background-color: #1b09d1;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: 12px; /* Reduced padding for a sleeker look */
  width: 100%;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 10px;
}

.submit-btn:hover, .submit-otp-btn:hover {
  background-color: #0f077b;
}

/* Google and Phone login options */
.login-options-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.google-login, .phone-login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  width: 48%;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.google-login:hover, .phone-login:hover {
  background-color: #d4d4d4;
}

.login-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Toggle between Login and SignUp */
.toggle-mode-wrapper {
  width: 100%; 
  margin: 20px 0;
  text-align: center; 
}

.toggle-mode {
  color: #333;
  font-size: 14px;
}

.toggle-mode-button {
  cursor: pointer;
  color: #1b09d1;
  font-weight: bold;
}

/* User type button styling */
.user-type-option {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 15px;
}

.form-group label {
  font-size: 13px;
}

.user-type-option button {
  flex: 1;
  padding: 10px;
  border: 1px solid #1b09d1;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  color: #1b09d1;
  transition: background-color 0.3s;
}

.user-type-option button.active {
  background-color: #1b09d1;
  color: #fff;
}

.user-type-option button:hover {
  background-color: #d4d4d4;
  color: #1b09d1;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .logo-section, .login-container {
    width: 100%;
    padding: 20px;
    box-shadow: none; /* Remove shadow for smaller screens */
    margin-top: 25%;
  }

  .inputBox, .login-options-row, .toggle-mode-wrapper {
    width: 100%;
  }

  .google-login, .phone-login {
    width: 100%;
    margin-top: 10px;
  }

  .submit-btn, .submit-otp-btn {
    width: 100%;
  }
}

.psw {
  display: flex;
  gap: 10px;
}

input {
  background-color: transparent;
}

input[id="lpsw"] {
  width: 100%;
}

/* Modal Background */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Dark semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background-color: white;
  padding: 30px 40px; /* Increased padding for more space */
  border-radius: 10px;
  width: 85%; /* Slightly decreased width for a more sleek appearance */
  max-width: 400px; /* Adjusted max width */
  text-align: center;
  position: relative;
  animation: slideIn 0.5s ease-out;
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

/* OTP Image */
.otp-image {
  width: 80%; /* Reduced width */
  height: 80%;
  margin-bottom: 20px; /* Reduced margin for better spacing */
}

/* OTP Input Fields */
.otp-input-container {
  display: flex;
  justify-content: center; /* Center the inputs */
  gap: 25px; /* Reduced gap between inputs */
  margin-bottom: 20px; /* Margin bottom for spacing */
}

.otp-input {
  width: 50px; /* Slightly larger width */
  height: 50px; /* Slightly larger height */
  text-align: center;
  font-size: 20px; /* Slightly reduced font size */
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
}

.otp-input:focus {
  border-color: #1b09d1;
}

/* Submit Button */
.submit-btn, .submit-otp-btn {
  background-color: #1b09d1;
  color: white;
  border: none;
  padding: 15px 20px; /* Increased padding */
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px; /* Increased font size */
  transition: background-color 0.3s;
  width: 100%; /* Set width to 100% for full-width button */
  margin-top: 20px; /* Increased margin-top for spacing */
}

.submit-btn:hover, .submit-otp-btn:hover {
  background-color: #0d045c; /* Darker shade on hover */
}

/* Animation */
@keyframes slideIn {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.intl-tel-input {
  width: 100%;
}

/* Style for the Forgot Password link */
.forgot-password {
  text-align: center;
  margin: 15px 0;
}

.forgot-password-link {
  color: #0d045c;
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  transition: color 0.3s ease;
}

.forgot-password-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

button[type="submit"] {
  width: 100%;
  height: auto; /* Set height to auto for better button sizing */
  padding: 10px 0; /* Adjust padding for a more compact look */
}

button[type="submit"]:hover {
  background-color: #0d045c;
}
.submit-otp-btn{
  width: 100%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: visible;
}


.modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  position: relative;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
  text-align: center;
}


.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}


.otp-image {
  width: 80px;
  margin: 20px auto;
}


.otp-input-container {
  display: flex;
  width: 100%;
  gap: 10px;
}

 
.otp-input-container select {
  width: 20%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  transition: border 0.3s;
  position: relative; 
  z-index: 1;
}

.otp-input-container select:focus {
  border: 1px solid #007bff;
}

 
.otp-input-container input[type="tel"] {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  transition: border 0.3s;
}

.otp-input-container input[type="tel"]:focus {
  border: 1px solid #007bff;
}

 
.submit-otp-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.submit-otp-btn:hover {
  background-color: #0056b3;
}
/* Media queries for responsiveness */
@media (max-width: 768px) {
  .container {
    padding: 10px;
    overflow: auto;
    height: 150%;
  }

  .logo-section {
    margin-top: 10%;
    width: 100%;
    max-width: none;
    padding: 20px;
  }

  .login-container {
    margin-top: 10px;
    max-height: 200vh;
    width: 100%;
    max-width: none;
    padding: 20px;
    overflow: auto;
  }

  .inputBox, .login-options-row, .toggle-mode-wrapper {
    width: 100%;
  }

  .google-login, .phone-login {
    width: 100%;
    margin-top: 10px;
  }

  .submit-btn, .submit-otp-btn {
    width: 100%;
  }
}


