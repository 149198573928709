/* Main container for the booking section */
.my-bookings-container {
  padding: 40px; /* Increased padding for more space inside */
  max-width: 12000px;
  margin: 20px auto; /* Center the container with auto margins */
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f9; /* Light background color for the entire section */
  border-radius: 12px; /* Rounded corners for the main container */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  animation: fadeIn 0.8s ease-in-out; /* Smooth fade-in animation */
  overflow: hidden; /* Ensures child elements do not overflow */
}

/* User information container */
.my-bookings-user-info {
  padding-left: 10%;
  background-color: #202024;
  width: 100%;
  border-radius: 10px;
  padding: 30px 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  display: flex; /* Enable flexbox for alignment */
  justify-content: space-between; /* Distribute space evenly between child elements */
  align-items: center; /* Center align items vertically */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.my-bookings-user-info:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* Increase shadow on hover */
}

/* User info text */
.my-bookings-user-info p {
  margin: 10px 0; /* Increase spacing between text */
  font-weight: bold;
  color: #ffffff;
  font-size: 18px; /* Slightly larger font size for readability */
  transition: color 0.3s ease;
}

.my-bookings-user-info p:hover {
  color: #00c896; /* Brighter color on hover for interactivity */
}

/* Booking details section */
.my-bookings-details {
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff; /* White background for contrast */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  animation: fadeInUp 0.6s ease-out; /* Animate the table details */
  width: calc(100% - 40px); /* Ensure the width respects padding/margin */
  margin: 0 auto 20px auto; /* Center it horizontally */
  overflow-x: auto; /* Allow horizontal scrolling if needed */
}

/* Styled table for booking details */
.my-bookings-styled-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  text-align: left;
  background-color: #ffffff; /* Set background color for table */
  transition: all 0.3s ease;
  border-radius: 10px; /* Rounded corners for the table */
  overflow: hidden; /* Prevents overflow from rounded corners */
  margin-bottom: 20px; /* Add space below the table */
}



/* Table header styling */
.my-bookings-styled-table thead tr {
  background: linear-gradient(to right, #009879, #007e67); /* Gradient background for header */
  color: #ffffff;
  animation: slideDown 0.6s ease-out; /* Animate header appearance */
}

.my-bookings-styled-table th,
.my-bookings-styled-table td {
  padding: 15px;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.my-bookings-styled-table th::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #009879;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.my-bookings-styled-table th:hover::after {
  transform: scaleX(1); /* Line animation under header on hover */
}

/* Table row styling */
.my-bookings-styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.my-bookings-styled-table tbody tr:nth-of-type(even) {
  background-color: #f8f8f8;
}

.my-bookings-styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.my-bookings-styled-table tbody tr:hover {
  background-color: #e0f7fa;
  transform: scale(1.01); /* Slight zoom effect on row hover */
}

h1 {
  font-size: 32px; /* Slightly larger for emphasis */
  margin-bottom: 20px;
  color: #009879;
  text-align: center;
  animation: fadeIn 0.8s ease-in-out; /* Fade-in animation for the title */
}

h2 {
  font-size: 24px; /* Slightly larger for section headings */
  margin-bottom: 10px;
  color: #333;
  text-align: center;
  animation: fadeIn 0.8s ease-in-out; /* Fade-in animation for subtitle */
}

/* Animation Keyframes */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Button Styles */
.my-bookings-styled-table button {
  padding: 8px 12px;
  margin: 5px;
  font-size: 14px;
  color: #ffffff;
  background-color: #009879;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.my-bookings-styled-table button:hover {
  background-color: #007e67; /* Darker shade on hover */
  transform: translateY(-2px); /* Slight lift on hover */
}