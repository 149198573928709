* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Lexend;
}

.contact {
    position: relative;
    height: auto;
    padding: 50px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
}
.span-title{
    color:#000000;
}

.contact .content {
    max-width: 800px;
    text-align: center;
}

.contact .content h2 {
    font-size: 3em;
    font-weight: 900;
    color: #1b09d1;
    font-family: Lexend;
}

.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 30px; */
}

.contactWrapper {
    display: flex;
    width: 100%;
    max-width: 1200px;
    gap: 50px;
}

.contactInfo,
.contactForm {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background-color:  #f8f7ff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contactInfo .box,
.contactForm form .inputBox {
    margin-bottom: 20px;
    display: flex;
}

.contactInfo .box .icon,
.contactForm form .inputBox input[type="submit"] {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:20px;
}

.contactInfo .box .icon {
    min-width: 50px;
    height: 50px;
    background-color: #1b09d1;
    border-radius: 50%;
    margin-right: 20px;
    animation: icon-animation 1.5s infinite;
}

@keyframes icon-animation {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

.contactInfo .box .icon i {
    color: #fff;
    font-size: 1.5em;
}

.contactInfo .box .text {
    color: #222;
    font-family: Lexend;
}

.contactInfo .box .text h3 {
    font-weight: 500;
    color: #1b09d1;
    margin-bottom: 5px;
    font-family: Lexend;
    text-align: left;
}
.address{
    text-align: left;
}
.contactInfo .txt {
    color: #222;
    margin-top: 30px;
    font-weight: 500;
    border-left: 5px solid #01dbc2;
    padding-left: 10px;
    line-height: 1.5em;
    font-family: Lexend;
    text-align: left;
}

.contactInfo .sci {
    display: flex;
    gap: 15px;
    margin-top: 20px;
}

.contactInfo .sci li {
    list-style: none;
}

.contactInfo .sci li a {
    color: #222;
    font-size: 1.5em;
    transition: color 0.3s;
}

.contactInfo .sci li a:hover {
    color: #01dbc2;
}

.contactForm form {
    display: flex;
    flex-direction: column;
}

.contactForm form h2 {
    font-size: 2em;
    color: #1b09d1;
    margin-bottom: 20px;
}

.contactForm form .inputBox {
    position: relative;
    margin-bottom: 20px;
}

.contactForm form .inputBox input,
.contactForm form .inputBox textarea {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    color: #222;
    background: #ffffff;
    border: none;
    border-radius: 20px;
    padding: 10px;
}

.contactForm form .inputBox span {
    position: absolute;
    left: 0;
    padding: 10px 0;
    pointer-events: none;
    font-size: 1em;
    color: #222;
    transition: 0.3s;
    padding: 10px;
}

.contactForm form .inputBox input:focus ~ span,
.contactForm form .inputBox textarea:focus ~ span,
.contactForm form .inputBox input:valid ~ span,
.contactForm form .inputBox textarea:valid ~ span {
    transform: translateY(-20px);
    font-size: 0.9em;
    color: #01dbc2;
}

.contactForm form .inputBox input[type="submit"] {
    background-image: linear-gradient(to right, rgba(31, 5, 206, 0.975), rgb(10, 10, 10), rgb(20, 6, 52));
    background-size: 300%;
    background-position: left;
    border-radius: 25px;
    border-width: 0;
    color: #ffffff;
    font-size: 1.1rem;
    padding: 5px;
    font-family: "Lexend";
    font-weight: 900;
    width: 200px;
    height: 50px;
    box-shadow: 1px -1px 10px 1px #2c2c2c, 1px 1px 6px #282828;
    transition: background-position 0.5s ease-in-out, box-shadow 0.5s ease;
    text-align: center;
}

.contactForm form .inputBox input[type="submit"]:hover {
    background: #1b09d1;
}


@media (max-width: 991px) {
    .contact {
        padding: 50px 20px;
    }

    .contactWrapper {
        flex-direction: column-reverse;
        gap: 30px;
    }
}


@media (max-width: 992px) {
    .contactWrapper {
        margin-top: 100%;
        flex-direction: column-reverse;
    }
}
@media (max-width: 480px){
    .contactWrapper{
        margin-top: 110%;
    }
    .container{
        height: 100vh;
    }
}