.footer-container {
    background-color: #000000;
    padding: 20px 0;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    margin: 0 auto;
}

.footer-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-brand {
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-bottom: 10px;
}

.footer-brand p {
    margin-left: 1rem;
    color: #ffffff;
    text-decoration: none;
    font-size: 1.5rem;
    font-family: Lexend;
    font-weight: 900;
}

.footer-links ul {
    display: flex;
    list-style: none;
    gap: 1.8rem;
    margin: 0;
    margin-top: 35px;
    padding: 20px;
    font-family: Lexend;
    font-size: 1rem;
    font-weight: 900;
}

.footer-links ul li a {
    color: #ffffff;
    text-decoration: none;
    padding: 10px 0;
}

.footer-links ul li a:hover {
    text-decoration: underline;
}

.logo {
    height:120px;
    width: 120px;
    
}

.sci {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 10px 0 0 0;
    gap: 1rem;
}

.sci li a {
    color: #ffffff;
    font-size: 1.5rem;
    transition: color 0.3s;
}

.sci li a:hover {
    color: #1b09d1; /* Change to desired hover color */
}

.headingFooter {
    color: #ffffff;
    font-family: Lexend;
    font-size: 18px;
    margin-top:20px;
}

.line {
    height: 1px;
    width: 100%;
    background-color: #ffffff;
    margin: 20px 0 16px 0;
}

.footer-copyright {
    text-align: center;
    color: #ffffff;
    font-family: Lexend;
    font-size: 1rem;
    padding: 2px 0 1px 0;
}

/* Responsive styles */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-left {
        align-items: center;
    }

    .footer-links ul {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
}

@media (max-width: 480px) {
    .footer-brand p {
        font-size: 1rem;
    }

    .footer-links ul li a {
        font-size: 0.875rem;
    }

    .sci li a {
        font-size: 1.2rem;
    }
}
