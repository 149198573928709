.heading {
  font-family: 'Lexend', sans-serif;
  font-size: 3em;
  font-weight: 900;
  color: #ffffff;
  margin-bottom: 20px;
}

.services {
  color: #1b09d1;
}

.carousel {
  margin-top: 20px;
  background-color: #000000;
  width: 100%;
  text-align: center;
  padding-top: 20px;
  min-height: 100px; /* Ensure minimum height */
  height: auto; /* Adjust height based on content */
}

.slider-container {
  width: 80%;
  margin: 5rem auto;
}

.slick-slide {
  color: #ffffff;
}

.slick-prev:before,
.slick-next:before {
  color: #ffffff;
}

.image {
  height: 300px;
  width: 600px;
  margin-top: 20px;
  border-radius:10px;
}

.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  transform: translateY(-10px);
  padding: 0;
  padding-bottom: 20px; /* Ensure enough space for content at the bottom */
}

.carousel-container h2 {
  margin-top: 0;
  font-size: 2rem;
  color: #ffffff;
}

.carousel-container p {
  margin-top: 20px; /* Reduced margin to ensure paragraph fits well */
  font-size: 1.5rem;
  width: 80%;
  text-align: center;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .heading {
    font-size: 2.5em;
  }

  .slider-container {
    width: 90%;
  }

  .image {
    height: 250px;
    width: 500px;
    border-radius:10px;
  }

  .carousel-container h2 {
    font-size: 1.8rem;
    margin-top: 25px;
  }

  .carousel-container p {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 2em;
  }

  .slider-container {
    width: 95%;
  }

  .image {
    height: 200px;
    width: 400px;
    border-radius:10px;
  }

  .carousel-container h2 {
    font-size: 1.5rem;
    margin-top: 25px;
  }

  .carousel-container p {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 1.5em;
  }

  .slider-container {
    width: 100%;
  }

  .image {
    height: 150px;
    width: 300px;
    border-radius:10px;
  }

  .carousel-container h2 {
    font-size: 1.2rem;
  }

  .carousel-container p {
    font-size: 0.7rem;
  }
}
