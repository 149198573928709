.booknow-container {
  background-color: #ffffff;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10001;
  max-width: 700px;
  width: 100%;
  height: 40%;
  text-align: center;
}

.title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #1b09d1;
}

.form-group-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.form-group:last-child {
  margin-right: 0;
}

.input-container {
  position: relative;
  width: 100%;
}

.form-control {
  width: 100%;
  padding: 10px 40px 10px 10px;
  border: 1px solid #1b09d1;
  border-radius: 5px;
  outline: none;
}

.location-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #1b09d1;
  pointer-events: none;
  font-size: 20px;
}

.estimate-button {
  width: 100%;
  padding: 10px;
  background-color: #1b09d1;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.estimate-button:hover {
  background-color: #002246;
  cursor: pointer;
}

.estimate-result {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #333;
}

.close-button {
  background-color: transparent;
  color: black;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  border: none;
  outline: none;
}

@media (max-width: 768px) {
  .form-group-horizontal {
    flex-direction: column;
  }

  .form-group {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
