.navbar {
  display: grid;
  grid-template-rows: auto 1fr auto;
  align-items: start;
  justify-items: center;
  padding: 2rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  position: relative;
  opacity: 1;
}

.logo-home {
  height: 80px;
  width: 80px;
  align-items: top;
}

.navbar-content {
  align-items: center; 
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: top;
  padding-top: 10px;
}

.navbar-brand {
  display: flex;
  align-items: center;
  color: #ffffff;
}

.navbar-brand p {
  margin-left: 1rem;
  color: #ffffff;
  text-decoration: none;
  font-size: 1.5rem;
  font-family: Lexend;
  font-weight: 900;
}

.navbar-links ul {
  cursor: pointer;
  align-items: center;
  display: flex;
  list-style: none;
  gap: 4rem;
  margin: 0;
  padding: 0;
  font-family: Lexend;
  font-size: 1rem;
  font-weight: 900;
}

.navbar-links ul li {
  display: flex;
  align-items: center;
}

.navbar-links ul li a {
  color: #ffffff;
  text-decoration: none;
  padding: 20px 0;
}

.navbar-links ul li a:hover {
  color: #ffffff;
  transition: 0.3s ease;
}

.navbar-links ul li .button {
  margin-top: -10px;
}

.quote-box {
  background-color: transparent;
  border-radius: 10px;
  color: #ffffff;
  width: 80%;
  max-width: 800px;
  font-family: Lexend, italic;
  font-weight: 900;
  position: absolute;
  left: 4%;
  top: 45%;
  transform: translateY(-50%);
  text-align: left;
  margin-top: 2rem;
}

.main-quote {
  font-size: 4.5rem;
  margin-bottom: 0.5rem;
}

.main-quote .word {
  display: inline-block;
  opacity: 0;
  color: #ffffff;
  transform: translateY(20px);
  transition: opacity 1s, transform 1s;
}

.main-quote .word.visible {
  opacity: 1;
  transform: translateY(0);
}

.sub-quote {
  font-size: 1.8rem;
  font-weight: 900;
  color: #e9e9e9;
  margin-top: 0;
  padding: 20px;
  border-radius: 10px;
  width: 550px;
}

.sub-quote .span {
  color: #1b09d1;
}

.button-container {
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
}

.button {
  background-image: linear-gradient(to right, rgba(31, 5, 206, 0.975), rgb(10, 10, 10), rgb(20, 6, 52));
  background-size: 300%;
  background-position: left;
  border-radius: 25px;
  border-width: 0;
  color: #ffffff;
  font-size: 1.1rem;
  padding: 5px;
  font-family: Lexend;
  font-weight: 900;
  width: 200px;
  height: 50px;
  box-shadow: 1px -1px 10px 1px #2c2c2c, 1px 1px 6px #282828;
  transition: background-position 0.5s ease-in-out, box-shadow 0.5s ease;
}

.button:hover {
  background-position: right;
  box-shadow: 1px -2px 10px 1px #0b0047, 1px -1px 10px 1px #4612ff;
}

.profile-dropdown {
  position: relative;
  display: flex;
  align-items: center;
}

.profile-icon {
  cursor: pointer;
  color: #ffffff;
  font-size: 1.5rem;
  padding: 10px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.profile-icon:hover {
  background-color: #0a0a0a;
}

.dropdown-content {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  z-index: 1000;
  margin-top: 10px;
  width: 150px;
}

.dropdown-content p {
  padding: 10px;
  margin: 0;
  background-color: #f3f3fa;
  color: #000000;
  text-align: center;
  font-weight: bold;
}

.dropdown-content button {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #0a0a0a;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
}

.dropdown-content button:hover {
  background-color: #2f352c;
}

.profile-dropdown .dropdown-content::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 10px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #ffffff transparent;
}

.sub-quote {
  background-color: rgba(0, 0, 0, 0.9);
  width: 75%;
  padding-right: 0;
  padding: 2%;
  transform: translateY(20px);
  transition: opacity 1s, transform 1s;
}

.sub-quote.visible {
  opacity: 1;
  transform: translateY(0);
}

button[id="dropdown"] {
  background-color: #f3f3fa;
  color: black;
  border-top: 1px solid #000000;
}

.menu-icon {
  display: none;
}

.sidebar {
  display: none;
}

@media (max-width: 1054px) {
  .navbar {
    grid-template-rows: auto auto auto;
    align-items: start;
    padding: 0;
  }

  .navbar-content {
    flex-direction: row;
    align-items: center;
    padding: 1rem;
  }

  .menu-icon {
    display: block;
    position: absolute;
    margin-top: 43px;
    top: 15px;
    right: 20px;
    color: #ffffff;
    cursor: pointer;
  }

  .navbar-links {
    display: none;
  }

  .sidebar {
    display: flex;
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: #000;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: right 0.3s ease;
    z-index: 9999;
  }

  .sidebar.open {
    right: 0;
  }

  .close-btn {
    position: absolute;
    margin-top: 27px;
    margin-right: 25px;
    top: 15px;
    right: 20px;
    font-size: 2rem;
    cursor: pointer;
    color: white;
  }

  .sidebar ul {
    list-style: none;
    text-align: center;
  }

  .sidebar ul li {
    margin: 1.5rem 0;
    width: 50vw;
    padding: 10px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    transition: background 0.3s ease, transform 0.3s ease;
  }

  .sidebar ul li a {
    font-size: 1.5rem;
    color: white;
    text-decoration: none;
    transition: 0.3s ease;
  }

  .sidebar ul li:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: scale(1.05);
  }

  .sidebar ul li a:hover {
    color: #ffeb3b;
  }

  .quote-box {
    width: 90%;
    font-size: 2rem;
    left: 50%;
    top: 10%;
    transform: translate(-50%, 0);
  }

  .main-quote {
    font-size: 1.5rem;
    margin-top: 30%;
  }

  .sub-quote {
    font-size: 0.875rem;
    margin-bottom: 2rem;
    width: 100%;
  }

  .button-container {
    position: relative;
    text-align: center;
    margin-top: 38%;
    margin-right: 80%;
  }

  .button {
    width: 100vw;
    font-size: 0.875rem;
  }
}

@media (max-width: 600px) {
  .navbar {
    height: 100vh;
    background-size: cover;
  }

  .navbar-content {
    padding: 0.5rem;
  }

  .quote-box {
    font-size: 1.5rem;
    top: 10%;
  }

  .main-quote {
    font-size: 1.5rem;
    margin-top: 50%;
  }

  .sub-quote {
    font-size: 0.875rem;
    margin-bottom: 2rem;
    width: 100%;
  }

  .button-container {
    position: relative;
    text-align: center;
    margin-top: 75%;
    margin-right: 0%;
  }

  .button {
    width: 100vw;
    font-size: 0.875rem;
  }

  .sidebar ul li a {
    font-size: 1rem;
  }
}
