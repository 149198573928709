/* faq.css */
.faq-container {
  width: 80%;
  margin: auto;
  /* padding: 100px 0; */
  top:20%;
  box-sizing: border-box;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:space-around;
}

.faq-item {
  /* border-bottom: 1px solid #ccc; */
  padding: 25px 0;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  color: #1b09d1;
  font-size:24px;
}

.faq-icon {
  transition: transform 0.3s;
}

.faq-icon.open {
  transform: rotate(180deg);
}

.faq-answer {
  padding: 10px 0;
  color: #000000;
  font-size:20px;
  text-align: left;
}
