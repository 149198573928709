.estimate-options-container {
  display: flex;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  max-width: 1100px;
  margin: auto;
  position: relative;
  gap: 30px;
}

.estimate-options-left {
  flex: 1;
  padding: 20px;
  background-color: #f1f4ff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

.estimate-options-left h2 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.estimate-options-left p {
  font-size: 18px;
  color: #555;
  line-height: 1.6;
}

.estimate-options-right {
  flex: 1.5;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  border-radius: 12px;
}

.option-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.option-card:hover {
  background-color: #e1e7ff;
}

.option-card .icon {
  font-size: 35px;
  margin-right: 20px;
}

.option-card .label {
  flex: 1;
  font-size: 20px;
  font-weight: 500;
}

.option-card .arrow {
  font-size: 26px;
  color: #888;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: white;  /* Changed to white */
  border: none;
  border-radius: 50%;  /* To make the button circular */
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #888;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.close-button:hover {
  color: #1b09d1;
  background-color: #f1f4ff; /* Light background on hover */
}
