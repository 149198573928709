.App {
  text-align: center;
  width: 100%;
}
html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.contact-number {
  text-decoration: none;
  color: inherit;
}

.contact-number:hover {
  text-decoration: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Call Us Button Styles */
.call-us-button {
  position: fixed;
  bottom: 10px;
  right: 20px;
  background-color: #1b09d1;
  color: white;
  border: none;
  border-radius: 25px;
  width: auto;
  height: auto;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-decoration: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.call-us-button:hover {
  background-color: #0814fa;
}

.call-us-button i {
  font-size: 24px; /* Adjust the icon size if needed */
}

.para {
  font-family: Lexend;
  font-size: 14px;
  padding-left: 10px;
}

#home, #about, #services, #contact, #faq {
  display: block;
}

/* Dialog Box Styles */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-box {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  color: black;
  max-width: 90%;
  width: 400px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  position: relative; /* To position the close icon correctly */
  animation: fadeIn 0.3s ease-in-out;
}

.dialog-box h2 {
  color: #1b09d1;
  margin-bottom: 20px;
}

.dialog-box .contact-number {
  color: #000;
  font-weight: bold;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000;
}

.close-icon:hover {
  color: #1b09d1;
}

/* Fade-in animation for the dialog box */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@media (min-width: 728px){
  .App{
    width: 100%;
  }
}
@media (max-width: 480px){
  .App{
    width: 100%;
  }
}
@media (min-width: 480px){
  .App{
    width: 90%;
  }
}
@media (min-width: 350px){
  .App{
    width: 120%
  }
}
@media (min-width: 550px){
  .App{
    width: 95%;
  }
}

@media (min-width: 650px){
  .App{
    width: 115%;
  }
}
@media (min-width: 700px){
  .App{
    width: 95%;
  }
}
/* General App Container */
.App {
  text-align: center;
  width: 100vw; /* Full viewport width */
  max-width: 100vw; /* Prevent overflow */
  margin: 0 auto; /* Center the content */
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  width: 100vw; /* Full viewport width */
  padding: 20px;
  margin: 0;
}

.quote-box {
  width: calc(100vw - 40px); /* Dynamically calculate width with some padding */
  max-width: 100vw;
  text-align: center;
  padding: 1rem;
  margin: 0 auto;
}

.button-container {
  width: 100vw; /* Full viewport width */
  text-align: center;
  margin-top: 1.5rem;
}

.button {
  width: auto;
  max-width: 300px;
  font-size: 1.2rem;
  padding: 10px 20px;
}

.dialog-box {
  max-width: calc(100vw - 40px); /* Ensure dialog box adjusts dynamically to screen width */
  width: 400px; /* Default width */
  padding: 20px;
  margin: 0 auto;
}

/* Responsive Styles for Tablet and Mobile Views */
@media (max-width: 768px) {
  .quote-box, .button-container {
    width: 90vw; /* Ensure proper width for tablet view */
    padding: 10px;
  }

  .App {
    width: 100vw;
    max-width: 100vw;
  }

  .button {
    width: 80vw;
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .quote-box {
    width: 100vw;
    padding: 1rem;
    font-size: 1.4rem; /* Adjust for smaller screens */
  }

  .main-quote {
    font-size: 1.5rem;
  }

  .sub-quote {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .button-container {
    width: 100vw;
    margin: 1.5rem 0;
  }

  .button {
    width: 90vw;
    max-width: 250px;
    font-size: 1rem;
  }
}

@media (min-width: 1024px) {
  .quote-box, .button-container {
    width: 80vw; /* Reduce width slightly for larger screens */
  }

  .button {
    width: 20vw; /* Adjust button width based on viewport */
  }
}
