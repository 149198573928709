/* Full-page layout styling */
.enquiry-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #f8f7ff;
  padding: 20px;
  box-sizing: border-box;
}

.enquiry-form-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  max-height: 100%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  flex-direction: column;
}

@media (min-width: 768px) {
  .enquiry-form-container {
    flex-direction: row;
    height: 90vh;
  }
}

.enquiry-form-section {
  flex: 1;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
  overflow-y: auto;
  padding-top: 8%;
  position: relative; /* Added to position the suggestions dropdown */
}

.enquiry-form-title {
  font-weight: bold;
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.enquiry-form-subtitle {
  text-align: center;
  color: #666;
  margin-bottom: 20px;
}

.enquiry-form-group {
  margin-bottom: 15px;
  position: relative; /* Ensures error messages and suggestions dropdown are positioned correctly */
}

.enquiry-form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.enquiry-form-group input,
.enquiry-form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.enquiry-input-icon {
  position: relative;
}

.enquiry-input-icon i {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

.enquiry-input-icon input {
  padding-left: 36px;
}

.enquiry-form-group textarea {
  resize: vertical;
  min-height: 80px;
}

.location-input-container {
  position: relative;
}

.location-input-container input {
  width: calc(100% - 50px);
  display: inline-block;
}

.use-location-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.use-location-btn img {
  width: 24px;
  height: 24px;
}

/* Updated styles for suggestions dropdown */
.location-suggestions {
  position: absolute;
  z-index: 1000;
  background-color: #ffffff;
  width: calc(100% - 20px); /* Adjust width to match input field */
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 5px;
  padding: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds shadow for better visibility */
  list-style: none;
}

.location-suggestions li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
}

.location-suggestions li:hover {
  background-color: #f0f0f0;
}

.submit-btn {
  background-color: #1b09d1;
  width: 60%;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.enquiry-submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #1b09d1;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
}

.enquiry-submit-btn:hover {
  background-color: #0f083f;
}

.enquiry-form-buttons {
  display: flex;
  gap: 30px;
}

.enquiry-image-section {
  flex: 1;
  background-color: #f8f7ff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.enquiry-illustration-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

label {
  text-align: left;
}

input[id="destination"],
input[id="pickup"] {
  width: 100%;
}

i[id="loc"] {
  font-size: 20px;
  background-color: #1b09d1;
  padding: 8px;
  border-radius: 100%;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}

.user-type-options {
  display: flex;
  width: 95.5%;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.5s ease-in-out;
}

.popup-content {
  background-color: #fff;
  padding: 60px;
  border-radius: 8px;
  width: 1000px;
  text-align: center;
  position: relative;
  animation: popupShow 0.6s ease-out;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.close-popup-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.close-popup-btn:hover {
  background-color: #eee;
}

/* Keyframes for Animations */
@keyframes fadeIn {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

@keyframes popupShow {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes checkmarkDraw {
  0% {
    stroke-dashoffset: 100px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
}

.popup-checkmark {
  width: 50px;
  height: 50px;
  margin: 20px auto;
  animation-delay: 0s; /* First tick animation appears immediately */
}

.checkmark-circle {
  stroke: #205a20;
  background-color: #4CAF50;
  stroke-width: 2;
  fill: none;
  animation: checkmarkDraw 1s ease-out forwards;
}

.checkmark-check {
  stroke: #205a20;
  stroke-width: 5;
  fill: none;
  stroke-dasharray: 100px;
  stroke-dashoffset: 100px;
  animation: checkmarkDraw 0.5s 0.5s ease-out forwards;
}

.address-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: 20px;
  opacity: 0;
  animation: fadeInContainer 0.5s 1.1s forwards; /* Delayed for 1.1s after the tick animation */
}

.pickup-container,
.drop-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  flex: 1;
  max-width: 45%;
}

.popup-table-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  animation: fadeInTable 1s 1.7s forwards; /* Delayed for 1.7s after the address containers */
}

.popup-table-left,
.popup-table-right {
  width: 45%;
}

.popup-form-data-table {
  width: 100%;
  text-align: left;
  margin-top: 20px;
  border-collapse: collapse;
  animation: fadeInTable 1s forwards;
}

.popup-form-data-table td {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards;
}

.popup-form-data-table tr:nth-child(1) td { animation-delay: 1.8s; } /* Each row delayed by 0.1s after table appearance */
.popup-form-data-table tr:nth-child(2) td { animation-delay: 1.9s; }
.popup-form-data-table tr:nth-child(3) td { animation-delay: 2.0s; }
.popup-form-data-table tr:nth-child(4) td { animation-delay: 2.1s; }
.popup-form-data-table tr:nth-child(5) td { animation-delay: 2.2s; }
.popup-form-data-table tr:nth-child(6) td { animation-delay: 2.3s; }
.popup-form-data-table tr:nth-child(7) td { animation-delay: 2.4s; }

.popup-comments-container {
  text-align: center;
  margin-top: 20px;
  opacity: 0;
  animation: fadeInComments 1s 2.5s forwards; /* Delayed for 2.5s after table rows */
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  max-width: 100%;
  align-items: center;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInContainer {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInTable {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInComments {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Style for error messages */
.enquiry-form-group {
  position: relative; /* Ensures error messages can be positioned absolutely */
}

.error-message {
  position: absolute;
  top: 100%;
  left: 0;
  color: #d8000c;
  background-color: white;
  border: 1px solid #d8000c;
  padding: 12px 12px;
  border-radius: 4px;
  font-size: 12px;
  width: max-content;
  white-space: nowrap; /* Prevents text from wrapping */
  z-index: 10;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 2px; /* Adds a small gap between the input and error message */
}

.error-message::before {
  content: '';
  position: absolute;
  top: -5px;
  left: 10px; /* Adjust this to align the arrow correctly */
  border-width: 0 5px 5px 5px;
  border-style: solid;
  border-color: transparent transparent #d8000c transparent;
}

input:invalid + .error-message {
  display: block;
}

/* This ensures that the error message does not affect the input alignment */
.enquiry-form-group input:focus:invalid {
  box-shadow: none;
}
