.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
  }
  
  .modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    border-radius: 10px;
    position: relative;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .big-card {
    padding: 20px;
    text-align: center;
  }
  
  .estimate-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  p {
    font-size: 18px;
    margin: 10px 0;
  }
  
  .login-button {
    background-color:  #1b09d1;
    color: white;
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .login-button:hover {
    background-color:  #1b09d1;
  }
  